/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */
import React from "react";
import classNames from "classnames";
import * as s from "./ProductImage.module.scss";
import { navigate } from "gatsby";
import LazyLoad from "react-lazyload";
import { ImImage } from "react-icons/im";

const cn = classNames.bind(s);

interface ProductImageProps {
  imageUrl?: string;
  alt: string;
  page?: "container" | "catalog" | "single" | "singleProduct" | "imageSlider";
}

const ProductImage = ({ imageUrl, alt, page }: ProductImageProps) => {
  const handleClick = () => {
    if (page === "container" || page === "single" || page === "catalog")
      navigate("/products/" + alt);
  };

  return (
    <div
      onClick={() => handleClick()}
      className={cn(
        { [s.container]: page === "catalog" },
        { [s.homePage]: page === "container" },
        { [s.singleProduct]: page === "singleProduct" },
        { [s.imageSlider]: page === "imageSlider" },
        s.mainContainer
      )}
    >
      {imageUrl ? (
        <LazyLoad
          height={150}
          style={{
            alignSelf: "baseline",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            className={cn(
              s.image,
              { [s.imageContainer]: page === "container" },
              { [s.imageSingle]: page === "single" },
              { [s.imageCatalog]: page === "catalog" },
              { [s.imageImageSlider]: page === "imageSlider" }
            )}
            src={imageUrl}
            alt={alt}
          />
        </LazyLoad>
      ) : (
        <ImImage style={{ width: "100%", height: "2em" }} />
      )}
    </div>
  );
};

export { ProductImage };

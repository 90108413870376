/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */

import React, { useState } from "react";
import classNames from "classnames";
import * as s from "./SingleProductCard.module.scss";
import { ShopNowButton } from "../../atoms/ShopNowButton/ShopNowButton";
import { ProductImage } from "../../atoms/ProductImage/ProductImage";
import { useEffect } from "react";
import { getDescription } from "../../../helpers/descriptionHelper";
import { navigate } from "gatsby";

interface SingleProductCardProps {
  product: Product;
  cart?: boolean;
  orientation: "left" | "right" | "center";
  page: "container" | "catalog" | "single";
}
const cn = classNames.bind(s);

const SingleProductCard = ({
  product,
  cart,
  orientation,
  page,
}: SingleProductCardProps) => {
  const [description, setDescription] = useState("");
  const image = !cart
    ? {
        originalSrc: product?.images && product?.images[0]?.originalSrc,
      }
    : {
        originalSrc: product?.variant?.image?.originalSrc,
      };

  const [title, setTitle] = useState<string>();

  const titleToBackgroundMapper = () => {
    if (title?.includes("lion")) {
      return {
        catalog: {
          background:
            "linear-gradient(263.32deg, #E7EBD5 7.12%, #E1E6CC 62.09%, #E0E6CC 96.57%)",
        },
        single: {
          background: "#F6FFDA",
        },
        container: {},
      };
    } else if (title?.includes("reishi")) {
      return {
        catalog: {
          background: "linear-gradient(2deg, #c4e0e2,#d8e9ea)",
        },
        single: {
          background: "#EDFDFF",
        },
        container: {},
      };
    } else if (title?.includes("super")) {
      return {
        catalog: {
          background:
            "linear-gradient(263.52deg, #F4DDEA 6.44%, #F4DBE9 95.9%)",
        },
        single: {
          background: "#FFF2FA",
        },
        container: {},
      };
    }
    return {
      container: {
        backgroundColor: "transparent",
      },
      single: {
        backgroundColor: "transparent",
      },
      catalog: {},
    };
  };

  useEffect(() => {
    const split = product.title.split(" ");
    let i = 0;
    let title = "";
    while (i < split.length) {
      if (i === split.length - 1) title = title + "<b>" + split[i] + "</b>";
      else title = title + split[i] + " ";
      i = i + 1;
    }
    setTitle(title);
    const part = ["container", "single"].includes(page) ? 0 : 1;
    const desc = getDescription(product?.description || "", part);
    setDescription(desc as string);
  }, []);

  const handleClick = () => {
    navigate("/products/" + product?.handle);
  };

  return (
    <div
      onClick={() => handleClick()}
      key={product.id}
      className={cn(s[page], {
        [s[orientation]]: page === "container" || page === "single",
      })}
      style={{
        ...(page === "catalog" && titleToBackgroundMapper().catalog),
        ...((page === "single" || page === "container") &&
          titleToBackgroundMapper().single),

        ...(orientation === "center" &&
          page === "container" && {
            paddingTop: "48px",
          }),
      }}
    >
      {(page === "container" || page === "single") && (
        <ProductImage
          imageUrl={product?.images[1]?.originalSrc}
          alt={product.handle || product.title}
          page={page}
        />
      )}
      {page === "catalog" && (
        <ProductImage
          imageUrl={image?.originalSrc}
          alt={product.handle || product.title}
          page={page}
        />
      )}
      <div
        className={
          page === "container" || page === "single"
            ? s.wrapper
            : s.wrapperCatalog
        }
        style={{
          ...(orientation === "center" && { alignItems: "center" }),
        }}
      >
        {title ? (
          <p
            className={s.title}
            dangerouslySetInnerHTML={{ __html: title }}
          ></p>
        ) : null}
        <p
          className={cn(s.price, {
            [s.description]: page === "catalog" || page === "container",
          })}
          style={{ ...(orientation === "center" && { textAlign: "center" }) }}
        >
          {description}
        </p>
        <ShopNowButton handle={product.handle || product.title} />
      </div>
    </div>
  );
};

export { SingleProductCard };

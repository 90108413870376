/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from "react";
import * as s from "./ShopNowButton.module.scss";
import arrow from "../../../images/arrowReadMore.svg";
import { Link } from "gatsby";

interface ShopNowButtonProps {
  handle: string;
}

const ShopNowButton = ({ handle }: ShopNowButtonProps) => {
  return (
    <div className={s.container}>
      <Link to={"/products/" + handle} className={s.button}>
        Buy now <img src={arrow} alt="arrow"></img>
      </Link>
    </div>
  );
};

export { ShopNowButton };

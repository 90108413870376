// extracted by mini-css-extract-plugin
export var catalog = "SingleProductCard-module--catalog--TZOZt";
export var container = "SingleProductCard-module--container--1l6ld";
export var description = "SingleProductCard-module--description---iNDP";
export var image = "SingleProductCard-module--image--+tEYY";
export var left = "SingleProductCard-module--left--2HrdD";
export var price = "SingleProductCard-module--price--g3O6r";
export var right = "SingleProductCard-module--right--V23Tx";
export var single = "SingleProductCard-module--single--TnAzN";
export var textBold = "SingleProductCard-module--text-bold--GGOqf";
export var title = "SingleProductCard-module--title--0AkJS";
export var wrapper = "SingleProductCard-module--wrapper--g40FP";
export var wrapperCatalog = "SingleProductCard-module--wrapper-catalog--XgrhO";
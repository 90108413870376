// extracted by mini-css-extract-plugin
export var container = "ProductImage-module--container--8CBsQ";
export var image = "ProductImage-module--image--ZNTdX";
export var imageCatalog = "ProductImage-module--image-catalog--8VyaB";
export var imageContainer = "ProductImage-module--image-container--b13Xu";
export var imageImageSlider = "ProductImage-module--image-image-slider--yqBFB";
export var imageSingle = "ProductImage-module--image-single--vpk+t";
export var imageSlider = "ProductImage-module--image-slider--WE6T1";
export var mainContainer = "ProductImage-module--main-container--IJla9";
export var page = "ProductImage-module--page--wTsX1";
export var singleProduct = "ProductImage-module--single-product--s1uCh";